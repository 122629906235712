/** @jsxImportSource @emotion/react */
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import React, { useMemo, useState, useEffect } from 'react';
import LandingPage from "./pages/LandingPage";
import LoginScreen from "./pages/Login";
import ResetPasswordScreen from "./pages/ResetPassword";
import CheckEmailScreen from "./pages/CheckEmail";
import VerificationScreen from "./pages/Verification";
import NewPasswordScreen from "./pages/NewPassword";
import ForgotPassword from "./pages/ForgotPassword";
import { useGetPageContainerSyles } from "./index.styles";
import DeviceContextProvider from "./context/DeviceContext";
import { CookiesProvider } from "react-cookie";
import SignUp from "./pages/SignUp";
import Instructions from "./pages/Instructions";
// import { useSelector } from 'react-redux';
import "./App.css";
// import { baseUrl } from './connections';
import { baseUrl } from "./connections";

import axios from 'axios'
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import DeleteAccount from "./pages/AccountDelete/index";
import Rewards from "./pages/Rewards";
import Favourites from "./pages/Favourites";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import About from "./pages/About/about";
import Faq from "./pages/Home/FAQ/index";
import MoreBrands from "./pages/MoreBrands";
import MoreMails from "./pages/MoreMails";
import Notifications from "./pages/Notifications";
import PopupAdds from "./pages/PopupAdds/Addslist"
// PopupAdds
// /Addslist
import AppFooter from "./components/AppFooter/index"
// import { Notificationconsumer } from "./hooks/services/notifications";
// firebase
import { getMes } from './firebase';
import { toast } from 'react-toastify';
import { onMessageListener } from './firebase';
// import { UserContext } from "./context/UserContext";
// import Cookies from 'js-cookie';
// import { useContext } from "react";
import UserContextProvider from "./context/UserContext";

// import packageJson from "../package.json";
// import withClearCache from "./clearCache"
import CacheBuster from './clearCache'


const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/login",
    element: <LoginScreen />,
  },
  {
    path: "/reset-password",
    element: <ResetPasswordScreen />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />
  },
  {
    path: "/check-email",
    element: <CheckEmailScreen />,
  },
  {
    path: "/verification",
    element: <VerificationScreen />,
  },
  {
    path: "/new-password",
    element: <NewPasswordScreen />,
  },
  {
    path: "/signup",
    element: <SignUp />,
  },
  {
    path: "/instructions",
    element: <Instructions />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/favourites",
    element: <Favourites />,
  },
  {
    path: "/rewards",
    element: <Rewards />,
  },
  {
    path: "/Notifications",
    element: <Notifications />,
  },
  {
    path: "/PopUp-Adds",
    element: <PopupAdds/>,
  },
  // PopupAdds
// /Addslist
  // Notifications
  {
    path: "/my-account",
    element: <Profile />,
  },
  {
    path: "/delete-account",
    element: <DeleteAccount />,
  },
  // DeleteAccount
  {
    path: "/support",
    element: <AppFooter />,
  },
  {
    path: "/terms",
    element: <Terms />,
  },
  {
    path: "/privacy",
    element: <Privacy />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/faq",
    element: <Faq />,
  },
  {
    path: "/moreBrands",
    element: <MoreBrands />,
  },
  {
    path: "/moreMails",
    element: <MoreMails />,
  },

]);


// const ClearCacheComponent = withClearCache(MainApp);

// function App() {
//   return <ClearCacheComponent />;
// }
function App() {
  const checkSafari = () => {
    return /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window[`${"safari"}`] || (typeof safari !== 'undefined' && window[`${"safari"}`].pushNotification));
  }

  // const uPubnub = usePubNub();

  useEffect(() => {
    if (!checkSafari())
      getMes();


  }, []);

  useEffect(() => {
    if (!checkSafari()) {
      onMessageListener()
        .then((message) => {
          // toast.success(message.notification.title, message.notification.body)
          toast.success(message?.notification?.title)
          // alert(message?.notification?.title?.toString())
          //  return <Alert key="success" variant="success">
          //   <h6>{message.notification.title}</h6>
          //   <p>{message.notification.body}</p>
          // </Alert>
        })
        .catch((err) => {
          toast.error(JSON.strinigfy(err))
        });
    }
  });

  // const { currentUser } = useSelector((state) => state.auth);
  // /notification/update/seen%27
  // let userdata1 = JSON.parse(localStorage.getItem("user"));

  // const NotificationUpdateseen = () => {
  //   const payload ={
  //     "user_uuid" : currentUser?.data?.uuid
  //   }
  //   axios.put(`${baseUrl}/notification/update/seen`,payload, {
  //     headers: {
  //       "x-auth-token": currentUser && currentUser.token
  //     }
  //   })
  //   .then((res)=>{
  //    console.log(res)
  //   })
  //   .catch((err)=>{
  //     console.log(err)
  //   })
  // }

  // useEffect(()=>{
  //   setInterval(()=>{
  //     NotificationUpdateseen()
  //   },2000)
  // },[])
  let userdata = JSON.parse(localStorage.getItem("user"));

  // const { noticationdata, count,setPayloadsdata , setpage, } = Notificationconsumer();

  // const { noticationdata, setPayloadsdata } = Notificationconsumer();

  // useEffect(() => {
  //   // setPayloadsdata( currentUser?.data?.uuid);
  //   const interval = setInterval(() => (Notificationlist()), 10 * 60 * 1000)
  //   return () => {
  //     clearInterval(interval);
  //   }
  // }, [])

  const Notificationlist = () => {
    axios.get(`${baseUrl}/notification/list/consumer?page=&limit=10&user_uuid=${userdata?.uuid}`, {
      headers: {
        'x-auth-token': userdata?.token
      }
    })
      .then((res) => {
        // UserPayload(true)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const classes = useGetPageContainerSyles();

  <CacheBuster>
  {({ loading, isLatestVersion, refreshCacheAndReload }) => {
    if (loading) return null;
    if (!loading && !isLatestVersion) {
      // You can decide how and when you want to force reload
      refreshCacheAndReload();
    }
    return (
      <div className="App">
        <header className="App-header">
          <h1>Cache Busting - Example</h1>
          <p>
            Bundle version - <code>v{global.appVersion}</code>
          </p>
        </header>
      </div>
    );
  }}
</CacheBuster>

  return (
    <div css={classes.pageContainer}>
      <CookiesProvider>
        <DeviceContextProvider>
          <UserContextProvider>
            <RouterProvider router={router} />
          </UserContextProvider>
        </DeviceContextProvider>
      </CookiesProvider>
     </div>
  );



}

export default App;
