import { useEffect, useState, useContext } from "react";
import { baseUrl } from "../../connections";
import { UserContext } from "../../context/UserContext";


export const useCategory = () => {
  const { userDetails } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const [payload, setCatPayload] = useState(null);
  const [payload1, setCatPayload1] = useState(null);
  const [catDetaisPayload, setCatDetailsPayload] = useState();
  const [catDetails, setCatDetails] = useState();
  const [point, setPoint] = useState(0);
  const [defaultId, setDefaultId] = useState('')
  const [defaultName, setDefaultName] = useState('')
  const [reccurLoad, setCatReccurLoad] = useState(true);


  const [totalCount, setTotalCount] = useState(10)
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("x-auth-token", userDetails?.token);

  const CatList = () => {
    setIsLoading(true);
    const params = JSON.stringify(payload);
    const requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };

    fetch(`${baseUrl}/category/list?${payload}`, requestOptions)
      .then(async (response) => {
        const res = await response.text();
        if (response.status !== 200) {
          throw res;
        }
        return JSON.parse(res);
      })
      .then((result) => {
        setDefaultId(result[0]?.uuid)
        setDefaultName(result[0]?.name)
        setData([...result]);
        setError(false);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  }
  // useEffect(() => {
  //   if (payload) {
  //     CatList()
  //   }
  //   const interval = setInterval(() => CatList(), 180000)
  //   return () => {
  //     clearInterval(interval);
  //   }
  // }, [payload, payload1]);

  useEffect(() => {
    let interval;
    if (reccurLoad && payload && payload !== null) {
      CatList();
    }
    if (reccurLoad && payload && payload !== null && JSON.stringify(payload) !== "null") {
      interval = setInterval(() => CatList(), 180000);
    } else {
      // Clear the interval when reccurLoad is false
      clearInterval(interval);
    }
  
    return () => {
      // Cleanup to avoid memory leaks
      clearInterval(interval);
    };
  }, [payload, reccurLoad]);

  useEffect(() => {
    if (catDetaisPayload) {
      setIsLoading(true);
      const params = JSON.stringify(catDetaisPayload);
      const requestOptions = {
        method: "GET",
        headers: headers,
        redirect: "follow",
      };

      fetch(`${baseUrl}/mail/user/point/history?${catDetaisPayload}`, requestOptions)
        .then(async (response) => {
          const res = await response.text();
          if (response.status !== 200) {
            throw res;
          }
          return JSON.parse(res);
        })
        .then((result) => {
          setCatDetails([...result.data]);
          setTotalCount(result?.count)
          setPoint(result?.point);
          setError(false);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    }
  }, [catDetaisPayload]);

  return {
    categoryList: data,
    isCatLoading: isLoading,
    catError: error,
    catDetails,
    setCatPayload,
    setCatDetailsPayload,
    point,
    defaultId,
    defaultName,
    totalCount,
    setCatPayload1,
    payload1,
    setCatReccurLoad
  };
};
